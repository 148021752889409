import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCookieBite } from 'react-icons/fa';

export const CookieBanner = () => {
    const [isVisible, setIsVisible] = useState(false);
    const { t } = useTranslation('cookieBanner');
    const { t: t2 } = useTranslation('footer'); // Rename the second translation function
 

    useEffect(() => {
        // Check if the consent cookie is already set
        const consentGiven = document.cookie.split(';').some((item) => item.trim().startsWith('cookieConsent='));
        if (!consentGiven) {
            setIsVisible(true);
        }

        
    }, []);

    

    const handleAccept = () => {
        // Set a cookie to remember the user's consent
        document.cookie = "cookieConsent=true; path=/; max-age=31536000"; // 1 year
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div className="fixed z-[999] bottom-0 w-full bg-primary p-4 shadow-lg flex   justify-between items-center flex-wrap">
            <div className="lg:w-[80%] flex lg:flex-row flex-col lg:items-center items-start">
                <FaCookieBite className="text-yellow-500 text-3xl mr-3" />
                <div className='w-full'>
                    <h3 className="text-lg font-bold text-white"> {t('title')}</h3>
                    <p className="text-sm text-white md:mt-0 mt-2">
                        {t('desc')}
                    </p>
                </div>
            </div>
 
            <div className='flex md:w-1/6 w-full  lg:flex-row  flex-col lg:justify-center justify-start gap-5 lg:items-center'>
                <a className='text-start text-nowrap md:text-md text-sm text-slate-400 hover:text-slate-300 lg:mt-0 mt-2' href='privacy-policy'>{t2('privacy')}</a> 
                <button 
                    onClick={handleAccept} 
                    className="bg-secondary md:mt-0 mt-5 min-w-[100px] md:w-w-1/3 w-full  text-white rounded-lg py-2 px-4 hover:bg-blue-600 focus:outline-none">
                    OK
                </button>
            </div>

        </div>
    );
};
