import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaStar } from 'react-icons/fa';

// Define a Review component
const Review = ({ text, profilePic, name, description, rating }) => {
    return (
        <div className="!min-h-[290px] md:max-h-[302px] !min-w-[300px] border mx-4 border-gray-300 rounded-lg justify-between   md:w-1/2  w-full  text-start card2" data-aos-offset="150"  data-aos-once={true}>
            <div className='px-10'>
          
                <div className='flex items-center md:flex-row flex-col  justify-between mt-10  justify-center'>
                    
                    <div className="md:pb-3 pb-1 ">
                  
                        {/* <img src={profilePic} alt={`${name}'s profile`} className="w-20 h-20 rounded-full object-cover  " /> */}
                        <h3 className="text-md mb-2 text-gray-900">{name}</h3>
                    </div>
                    <div className="flex justify-center md:mb-4 mb-2 star">

                        {Array.from({ length: 5 }, (_, index) => (
                            <FaStar
                                key={index}
                                className={` text-yellow-500 ${index < rating ? 'fill-current' : 'text-gray-300'}`}
                            />
                        ))}
                    </div>
                </div>
                <div className='flex mt-1 justify-center'>
                    <blockquote className=" text-lg text-center mb-4 text-gray-800">
                        "{text}"
                    </blockquote>
                </div>
                
            </div>
       
            {/* <div className='flex'>
                    <div className='w-1/3 h-[20px] bg-last'></div>
                    <div className='w-1/3 h-[20px] bg-secondary'></div>
                    <div className='w-1/3 h-[20px] bg-primary'></div>
            </div> */}
        </div>
        
    );
};
// Styles for the component

// Example usage of the Review component
export const Reviews = ({cta_}) => {
    const { t } = useTranslation("reviews");

    return (
        <div className='pt-10 w-full mt-[-50px] '>
          
            <div className='dots w-full relative animated z-[-10] mb-10 '>
                {cta_ && <> 
                    <h2 className="md:w-full w-[90%] md:my-10 my-5 md:text-start text-center text-black md:mt-[-12px] text-start flex flex-wrap items-center justify-center" data-aos-once={true} data-aos="fade-in" data-aos-duration="500">
                        <span className='md:text-start text-center '>{t('cta-start2')} </span>
                        <span className="sm:ml-3 ml-8 highlight bg-[#cbccff] rounded p-2 ">{t('cta-start3')}</span> 
                        <img   src="/img/mouse.svg" height={40} width={40} className="mt-12 ml-[-5px]" /> 
                    </h2>
                </>}
                <div className=' flex md:flex-nowrap flex-wrap gap-10 md:gap-40 justify-center items-center mt-10 md:mb-0 mb-11 '>
                        
                        <Review 
                            text={t('review-illias')}
                            name="Illias A."
                            description="Sweetspot"
                            rating={5}
                            
                    
                        />

                        <Review 
                            text={t('review-sandy')}
                            name="Sandy B."
                            description="MODOSKYNN Beauty Lounge"
                            rating={5}
                            
                    
                        />
                    
                </div>
            </div>
        </div>


    );
};
